import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { Rating, TextareaAutosize } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { styled } from "@mui/system";
import Header from "../ui/layout/Header";
import Footer from "../ui/layout/Footer";
import AuthModal from "../ui/modals/AuthModal";
import PlaceIcon from "@mui/icons-material/Place";
import { formatRating } from "../utils/formatters";
import ReviewCard from "../ui/cards/ReviewCard";
import Loader from "../ui/loaders/Loader";
import useStore from "../../store/store";

const DEFAULT_PRODUCT = null;
const DEFAULT_REVIEWS = null;
const DEFAULT_PRODUCT_RATING = 0;
const DEFAULT_PRODUCT_COMMENT = "";
const DEFAULT_PRODUCT_IMAGE = null;
const DEFAULT_IS_REVIEW_FORM_OPEN = false;
const DEFAULT_SHOULD_SUBMIT_REVIEW = false;
const DEFAULT_IS_AUTH_MODAL_OPEN = false;
const DEFAULT_IS_LOADING = false;

const StyledRating = styled(Rating)({
    "& .MuiRating-iconEmpty": {
        color: "#fff",
    }
});

const Product = () => {
    const [product, setProduct] = useState(DEFAULT_PRODUCT);
    const [reviews, setReviews] = useState(DEFAULT_REVIEWS);
    const [productRating, setProductRating] = useState(DEFAULT_PRODUCT_RATING);
    const [productComment, setProductComment] = useState(DEFAULT_PRODUCT_COMMENT);
    const [productImage, setProductImage] = useState(DEFAULT_PRODUCT_IMAGE);
    const [isReviewFormOpen, setIsReviewFormOpen] = useState(DEFAULT_IS_REVIEW_FORM_OPEN);
    const [shouldSubmitReview, setShouldSubmitReview] = useState(DEFAULT_SHOULD_SUBMIT_REVIEW);
    const [isAuthModalOpen, setIsAuthModalOpen] = useState(DEFAULT_IS_AUTH_MODAL_OPEN);
    const [isLoading, setIsLoading] = useState(DEFAULT_IS_LOADING);
    const { productId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { isUserLoading, user, getUser } = useStore((state) => ({
        isUserLoading: state.isUserLoading,
        user: state.user,
        getUser: state.getUser
    }));

    const productCommentRef = useRef();
    const productImageRef = useRef();

    useEffect(() => {
        init();
        if (location.search.includes("review=success")) {
            toast.success("Review submitted successfully!");
        }
    }, [productId, location.search]);

    const init = async () => {
        try {
            setIsLoading(true);
            await getProduct();
            await getReviews();
        } catch (err) {
            console.error(err);
            toast.error("Error initializing");
        } finally {
            setIsLoading(DEFAULT_IS_LOADING);
        }
    };

    const getProduct = async () => {
        try {
            const { data, status } = await axios.get("/api/e/v1/product/get_product", {
                params: {
                    productId
                }
            });
            if (status === 200) {
                const product = data?.product;
                setProduct(product);
            } else {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error getting product");
        }
    };

    const getReviews = async () => {
        try {
            const { data, status } = await axios.get("/api/e/v1/product/get_reviews", {
                params: {
                    productId
                }
            });
            if (status === 200) {
                const reviews = data.reviews;
                setReviews(reviews);
            } else {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error getting reviews");
        }
    };

    const handleImageUpload = async (e) => {
        let file = e.target.files[0];
        if (file && file.type === "image/heic") {
            const heic2any = require("heic2any");
            try {
                const convertedBlob = await heic2any({ blob: file, toType: "image/jpeg" });
                file = new File([convertedBlob], file.name.replace(/\.[^/.]+$/, ".jpg"), { type: "image/jpeg" });
            } catch (err) {
                console.error("Error converting HEIC to JPEG", err);
                toast.error("Error converting HEIC to JPEG");
                return;
            }
        }
        setProductImage(file);
    };

    const handleSubmitReview = async (override = false) => {
        try {
            if (!productRating) {
                toast.error("Please rate the product");
                return;
            }
            if (!productComment) {
                toast.error("Please write your review");
                productCommentRef.current.focus();
                return;
            }
            if (!productImage) {
                toast.error("Please upload an image");
                productImageRef.current.focus();
                return;
            }
            if (!isUserLoading && !user.username && !override) {
                toast.warning("Please sign up or log in first to submit your review");
                setShouldSubmitReview(true);
                handleOpenAuthModal();
                return;
            }
            const formData = new FormData();
            formData.append("store_name", product.store_name);
            formData.append("store_location", product.store_location);
            formData.append("product_name", product.product_name);
            formData.append("product_type", product.product_type);
            formData.append("product_brand", product.product_brand);
            formData.append("product_price", product.product_price);
            formData.append("product_image", productImage);
            formData.append("rating", productRating);
            formData.append("comment", productComment);
            const { data, status } = await axios.post("/api/e/v1/product/create_review", formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });
            if (status === 200) {
                getProduct();
                getReviews();
                handleCloseAuthModal();
                setIsReviewFormOpen(false);
                setProductRating(DEFAULT_PRODUCT_RATING);
                setProductComment(DEFAULT_PRODUCT_COMMENT);
                setProductImage(DEFAULT_PRODUCT_IMAGE);
                toast.success("Review submitted successfully!");
            } else {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error submitting review");
        }
    };

    const handleOpenAuthModal = () => {
        setIsAuthModalOpen(true);
    };

    const handleCloseAuthModal = () => {
        setIsAuthModalOpen(DEFAULT_IS_AUTH_MODAL_OPEN);
    };

    return (
        <div className="page bg-authBackground min-h-screen flex flex-col justify-start full">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 full">
                <div className="flex flex-col items-center gap-4 py-5 text-center full">
                    <Header handleOpenModal={handleOpenAuthModal} />
                    <div className="flex justify-start items-center text-white mt-2 cursor-pointer full" onClick={() => navigate("/")}>
                        <ArrowBackIosIcon />
                        <span className="ml-1">Back</span>
                    </div>
                    {isLoading ?
                        <Loader /> :
                        <>
                            {product && (
                                <div key={product.id} className="flex flex-col items-center" onClick={() => navigate(`/product/${product.id}`)}>
                                    <img src={product.product_image} alt={product.product_name} className="max-w-full h-auto object-cover" style={{ maxHeight: "100vh" }} />
                                    <div className="flex flex-row justify-between w-full mt-2 padding">
                                        <div className="flex flex-col items-start gap-2 w-3/5">
                                            <h2 className="truncate">{product.product_name}</h2>
                                            <small className="truncate">by {product.product_brand}</small>
                                            <a href={product?.store_url} target="_blank" rel="noopener noreferrer" className="flex items-center gap-1 p-0 cursor-pointer" onClick={(e) => e.stopPropagation()}>
                                                <PlaceIcon style={{ margin: "0px", fontSize: "small" }} />
                                                <small className="text-left">{product.store_name}</small>
                                            </a>
                                        </div>
                                        <div className="flex flex-col items-end gap-2 w-2/5">
                                            <h2 className="truncate">{formatRating(product.reviews_average)}</h2>
                                            <Rating name="read-only" value={product.reviews_average} readOnly style={{ margin: "0px", fontSize: "small" }} />
                                            <small className="truncate">({product.reviews_count} review{product.reviews_count === 1 ? "" : "s"})</small>
                                            <p className="truncate text-[#9dff5b]">{product.product_price}</p>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {isReviewFormOpen ?
                                <>
                                    <button className="button-cancel" onClick={() => setIsReviewFormOpen(false)}>
                                        <p className="text-white">Cancel</p>
                                    </button>
                                    <div className="flex-col-left-top full">
                                        <h1>New Review</h1>
                                    </div>
                                </> :
                                <>
                                    <button className="button-primary" onClick={() => setIsReviewFormOpen(true)}>
                                        <p className="text-black">Leave a Review</p>
                                    </button>
                                    <div className="flex-col-left-top full">
                                        <h1>Reviews</h1>
                                    </div>
                                </>
                            }
                            {product && reviews &&
                                (isReviewFormOpen ?
                                    <>
                                        <div className="flex-col-left-top gap full padding">
                                            <h3 className="text-sm sm:text-lg lg:text-xl">Was It Worth The Price?</h3>
                                            <StyledRating
                                                value={productRating}
                                                onChange={(e, rating) => {
                                                    setProductRating(rating);
                                                }}
                                            />
                                            <h3 className="text-sm sm:text-lg lg:text-xl">Write Your Review</h3>
                                            <TextareaAutosize
                                                fullWidth
                                                className="text-white bg-[#3A5936] border-white border rounded-md p-2 outline-none"
                                                value={productComment}
                                                onChange={(e) => setProductComment(e.target.value)}
                                                ref={productCommentRef}
                                                maxLength={250}
                                            />
                                            <small>{productComment.length}/250</small>
                                            <h3 className="text-sm sm:text-lg lg:text-xl">Upload Image</h3>
                                            <input 
                                                type="file" 
                                                accept=".jpg,.jpeg,.png,.heic" 
                                                onChange={handleImageUpload} 
                                                className="text-white" 
                                                ref={productImageRef} 
                                            />
                                            <div className="flex justify-center mt-1 sm:mt-2 full">
                                                <button className="button-primary" onClick={() => handleSubmitReview()}>
                                                    <p className="text-black">Submit Review</p>
                                                </button>
                                            </div>
                                        </div>
                                    </> :
                                    <>
                                        {reviews.sort((a, b) => b.rating - a.rating).map((review) => (
                                            <ReviewCard key={review.id} product={product} review={review} />
                                        ))}
                                    </>
                                )
                            }
                        </>
                    }
                    <Footer />
                </div>
                <AuthModal getUser={getUser} isModalOpen={isAuthModalOpen} handleCloseModal={handleCloseAuthModal} shouldSubmitReview={shouldSubmitReview} handleSubmitReview={handleSubmitReview} />
            </div>
        </div>
    );
};

export default Product;