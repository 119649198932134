import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Box, Modal } from "@mui/material";
import PhoneNumberInput from "../inputs/PhoneNumberInput";
import VerificationCodeInput from "../inputs/VerificationCodeInput";
import UsernameInput from "../inputs/UsernameInput";

const DEFAULT_PHONE_NUMBER = "";
const DEFAULT_VERIFICATION_CODE = ["", "", "", "", "", ""];
const DEFAULT_VERIFICATION_CODE_RESTART = false;
const DEFAULT_USERNAME = "";
const DEFAULT_STEP_SEND_CODE = "sendCode";
const DEFAULT_STEP_VERIFY_CODE = "verifyCode";
const DEFAULT_STEP_USERNAME = "username";
const RESEND_TIMEOUT = 30000;

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    height: "80%",
    backgroundImage: "radial-gradient(circle, #417439, black)",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4
};

const AuthModal = ({ getUser, isModalOpen, handleCloseModal, shouldSubmitReview, handleSubmitReview }) => {
    const [phoneNumber, setPhoneNumber] = useState(DEFAULT_PHONE_NUMBER);
    const [verificationCode, setVerificationCode] = useState(DEFAULT_VERIFICATION_CODE);
    const [verificationCodeRestart, setVerificationCodeRestart] = useState(DEFAULT_VERIFICATION_CODE_RESTART);
    const [username, setUsername] = useState(DEFAULT_USERNAME);
    const [step, setStep] = useState(DEFAULT_STEP_SEND_CODE);
    const [resendDisabled, setResendDisabled] = useState(false);
    const [resendTimeout, setResendTimeout] = useState(RESEND_TIMEOUT / 1000);
    const [message, setMessage] = useState("");
    const [messageColor, setMessageColor] = useState("text-red-500");
    const navigate = useNavigate();
    const resendTimer = useRef(null);

    useEffect(() => {
        setPhoneNumber(DEFAULT_PHONE_NUMBER);
        setVerificationCode(DEFAULT_VERIFICATION_CODE);
        setUsername(DEFAULT_USERNAME);
        setStep(DEFAULT_STEP_SEND_CODE);
        setMessage("");
    }, [isModalOpen]);

    useEffect(() => {
        if (resendDisabled && resendTimeout > 0) {
            resendTimer.current = setInterval(() => {
                setResendTimeout((prevTimeout) => prevTimeout - 1);
            }, 1000);
        } else if (!resendDisabled && resendTimeout !== RESEND_TIMEOUT / 1000) {
            clearInterval(resendTimer.current);
            setResendTimeout(RESEND_TIMEOUT / 1000);
        } else if (resendDisabled && resendTimeout === 0) {
            setResendDisabled(false);
        }
        return () => clearInterval(resendTimer.current);
    }, [resendDisabled, resendTimeout]);

    useEffect(() => {
        setMessage("");
    }, [step])

    const handleSendVerificationCode = async () => {
        try {
            const { data, status } = await axios.post(`${process.env.REACT_APP_AWS_API_GATEWAY}/api/e/v1/auth/twilio/send_verification_code`, {
                phone_number: phoneNumber
            });
            if (status === 200) {
                const phone_number_formatted = data?.phone_number_formatted;
                const verification_status = data?.verification_status;
                if (phone_number_formatted !== undefined) {
                    setPhoneNumber(phone_number_formatted);
                }
                if (verification_status === "pending") {
                    setStep(DEFAULT_STEP_VERIFY_CODE);
                    setResendDisabled(true);
                    setResendTimeout(RESEND_TIMEOUT / 1000);
                    setMessage("Verification code sent. Please check your phone.");
                    setMessageColor("text-green-500");
                }
            } else {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            if (err.response.status === 429) {
                setMessage("Too many requests. Please try again later.");
            } else {
                setMessage("Error sending verification code.");
            }
            setMessageColor("text-red-500");
        }
    };

    const handleVerifyCode = async () => {
        try {
            const { data, status } = await axios.post(`${process.env.REACT_APP_AWS_API_GATEWAY}/api/e/v1/auth/twilio/verify_code`, {
                phone_number: phoneNumber,
                verification_code: verificationCode.join("")
            });
            if (status === 200) {
                const is_new_user = data?.is_new_user;
                const verification_check_status = data?.verification_check_status;
                if (verification_check_status === "approved") {
                    if (is_new_user) {
                        setStep(DEFAULT_STEP_USERNAME);
                    } else {
                        await getUser();
                        if (shouldSubmitReview) {
                            handleSubmitReview(true);
                        } else {
                            handleCloseModal();
                        }
                    }
                }
            } else if (status === 401) {
                const verification_check_status = data?.verification_check_status;
                throw new Error({ err: verification_check_status, status });
            } else {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            setVerificationCodeRestart(true);
            console.error(err);
            setMessage("Error verifying code.");
            setMessageColor("text-red-500");
        }
    };

    const handleCreateAccount = async () => {
        try {
            const { data, status } = await axios.post(`${process.env.REACT_APP_AWS_API_GATEWAY}/api/e/v1/auth/update_user`, {
                username
            });
            if (status === 200) {
                const user = data?.user;
                if (user) {
                    await getUser();
                    if (shouldSubmitReview) {
                        handleSubmitReview(true);
                    } else {
                        handleCloseModal();
                    }
                }
            } else {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            if (err?.response?.status === 400) {
                setMessage("Username already taken.");
            } else {
                setMessage("Error creating account.");
            }
            setMessageColor("text-red-500");
        }
    };

    return (
        <Modal
            open={isModalOpen}
            onClose={handleCloseModal}
            className="mx-auto max-w-xs sm:max-w-md md:max-w-lg lg:max-w-xl"
        >
            <Box sx={style} className="max-h-75vh overflow-y-auto">
                <div className="flex flex-col items-start justify-center gap-4 p-4 w-full">
                    <img src="/images/WeedWise-Icon.png" alt="WeedWise Icon" onClick={() => navigate("/")} className="w-16 h-16" />
                    <div className="flex flex-col items-start justify-center gap-2 w-full">
                        <h1 className="text-2xl">Sign In</h1>
                        {step === "username" ?
                            <p className="text-lg">Choose a username</p> :
                            <p className="text-lg">We will send an SMS with an activation code to your phone</p>
                        }
                    </div>
                    <div className="flex flex-col items-center justify-center gap-2 w-full">
                        {step === "sendCode" ? (
                            <>
                                <PhoneNumberInput phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} className="w-full" />
                                {message && <div className={`${messageColor} mt-2`}>{message}</div>}
                                <button className="button-primary w-full" onClick={handleSendVerificationCode}>
                                    <p className="text-black">Send Code</p>
                                </button>
                            </>
                        ) : step === "verifyCode" ? (
                            <>
                                <VerificationCodeInput
                                    verificationCode={verificationCode}
                                    setVerificationCode={setVerificationCode}
                                    verificationCodeRestart={verificationCodeRestart}
                                    setVerificationCodeRestart={setVerificationCodeRestart}
                                    className="w-full"
                                />
                                {message && <div className={`${messageColor} mt-2`}>{message}</div>}
                                <button className="button-primary w-full" onClick={handleVerifyCode}>
                                    <p className="text-black">Verify Code</p>
                                </button>
                                <button className="button-secondary w-full mt-2" onClick={handleSendVerificationCode} disabled={resendDisabled}>
                                    <p className="text-white">{resendDisabled ? `Resend Code (${resendTimeout}s)` : "Resend Code"}</p>
                                </button>
                            </>
                        ) : step === "username" ? (
                            <>
                                <UsernameInput username={username} setUsername={setUsername} className="w-full" />
                                {message && <div className={`${messageColor} mt-2`}>{message}</div>}
                                <button className="button-primary w-full" onClick={handleCreateAccount}>
                                    <p className="text-black">Create Account</p>
                                </button>
                            </>
                        ) : <></>
                        }
                    </div>
                    <div className="flex justify-center mt-4 full">
                        <button className="button-secondary" onClick={handleCloseModal}>
                            <p className="text-white">Exit</p>
                        </button>
                    </div>
                </div>
            </Box>
        </Modal>
    );
}

export default AuthModal;