import React, { useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Home from "./pages/Home";
import Review from "./pages/Review";
import Product from "./pages/Product";
import Leaderboard from "./pages/Leaderboard";
import useStore from "../store/store";

const App = () => {
  const { isUserLoading, user, getUser } = useStore((state) => ({
    isUserLoading: state.isUserLoading,
    user: state.user,
    getUser: state.getUser
  }));
  const navigate = useNavigate();

  useEffect(() => {
    getUser();
  }, []);

  return (<>
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={true}
      newestOnTop={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable={false}
      pauseOnHover
      theme="light"
      limit={1}
      toastStyle={{ width: "auto", minWidth: "250px" }}
    />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/review" element={<Review />} />
      <Route path="/product/:productId" element={<Product />} />
      <Route path="/leaderboard" element={<Leaderboard />} />
      <Route path="*" element={<Navigate to="/ " replace />} />
    </Routes>
  </>);
};

export default App;