import React from "react";

const Loader = () => {
    return (<svg
        className="spinner w-16 h-16 md:w-16 md:h-16"
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            className="path"
            fill="none"
            strokeWidth="6"
            strokeLinecap="round"
            cx="33"
            cy="33"
            r="30"
        ></circle>
    </svg>);
};

export default Loader;