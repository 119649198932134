import React from "react";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";

const Footer = () => {
    return (
        <div className="flex-col-center-center gap full">
            <h1 className="text-center md:text-left">Start getting better weed.</h1>
            <div className="flex flex-col md:flex-row justify-center md:justify-between items-center full">
                <p className="text-center md:text-left">© WeedWise 2023</p>
                <div className="flex-row-center-center gap">
                    <a href="https://twitter.com/getweedwise" target="_blank" rel="noopener noreferrer">
                        <XIcon style={{ color: "white" }} />
                    </a>
                    <a href="https://www.instagram.com/getweedwise" target="_blank" rel="noopener noreferrer">
                        <InstagramIcon style={{ color: "white" }} />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Footer;