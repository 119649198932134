import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MenuItem, Select } from "@mui/material";
import { toast } from "react-toastify";
import axios from "axios";
import Header from "../ui/layout/Header";
import Footer from "../ui/layout/Footer";
import AuthModal from "../ui/modals/AuthModal";
import ProductCard from "../ui/cards/ProductCard";
import Loader from "../ui/loaders/Loader";
import useStore from "../../store/store";

const DEFAULT_PRODUCTS = null;
const DEFAULT_PRODUCT_TYPE = "all";
const DEFAULT_IS_AUTH_MODAL_OPEN = false;
const DEFAULT_IS_LOADING = false;

const Home = () => {
    const [products, setProducts] = useState(DEFAULT_PRODUCTS);
    const [productType, setProductType] = useState(DEFAULT_PRODUCT_TYPE);
    const [isAuthModalOpen, setIsAuthModalOpen] = useState(DEFAULT_IS_AUTH_MODAL_OPEN);
    const [isLoading, setIsLoading] = useState(DEFAULT_IS_LOADING);
    const navigate = useNavigate();
    const { isUserLoading, user, getUser } = useStore((state) => ({
        isUserLoading: state.isUserLoading,
        user: state.user,
        getUser: state.getUser
    }));

    useEffect(() => {
        getProducts();
    }, []);

    const getProducts = async () => {
        try {
            setIsLoading(true);
            const { data, status } = await axios.get("/api/e/v1/product/get_products");
            if (status === 200) {
                const products = data?.products;
                setProducts(products);
            } else {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error getting products");
        } finally {
            setIsLoading(DEFAULT_IS_LOADING);
        }
    };

    const handleOpenAuthModal = () => {
        setIsAuthModalOpen(true);
    };

    const handleCloseAuthModal = () => {
        setIsAuthModalOpen(DEFAULT_IS_AUTH_MODAL_OPEN);
    };

    return (
        <div className="page bg-authBackground min-h-screen flex flex-col justify-start full">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 full">
                <div className="flex flex-col items-center gap-4 py-5 text-center full">
                    <Header handleOpenModal={handleOpenAuthModal} />
                    <h1 className="text-lg sm:text-xl lg:text-2xl">Find NYC weed <i className="highlight">worth</i> buying.</h1>
                    <p className="text-sm sm:text-base lg:text-lg">We use real customer reviews to sort different products based on <i>price</i> & <i>quality</i>.</p>
                    <small className="text-xs sm:text-sm lg:text-base"><i>Must be 21 or over.</i></small>
                    <button className="button-primary mt-2" onClick={() => navigate("/review")}>
                        <p className="text-black">Leave a Review</p>
                    </button>
                    <div className="flex flex-col items-center gap-2 w-full text-center">
                        <h2 className="text-lg sm:text-xl lg:text-2xl">Top Picks</h2>
                        <hr style={{ width: "100%", borderTop: "1px solid white" }} />
                        <div className="flex flex-col items-center gap-2 w-full text-center">
                            <Select
                                fullWidth
                                value={productType}
                                onChange={(e) => setProductType(e.target.value)}
                                style={{ color: "white" }}
                                defaultValue="all"
                            >
                                <MenuItem value="all" style={{ color: "black" }}>All</MenuItem>
                                <MenuItem value="flower" style={{ color: "black" }}>Flower</MenuItem>
                                <MenuItem value="cart" style={{ color: "black" }}>Cart</MenuItem>
                                <MenuItem value="edible" style={{ color: "black" }}>Edible</MenuItem>
                            </Select>
                        </div>
                        {isLoading ?
                            <Loader /> :
                            <>
                                {products &&
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                        {products
                                            .filter(product => productType === "all" || product.product_type === productType)
                                            .sort((a, b) => b.reviews_average - a.reviews_average)
                                            .map((product) => (
                                                <ProductCard key={product.id} product={product} />
                                            ))}
                                    </div>
                                }
                            </>
                        }
                    </div>
                    <Footer />
                </div>
                <AuthModal getUser={getUser} isModalOpen={isAuthModalOpen} handleCloseModal={handleCloseAuthModal} />
            </div>
        </div>
    );
};

export default Home;