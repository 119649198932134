import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import Header from "../ui/layout/Header";
import Footer from "../ui/layout/Footer";
import Loader from "../ui/loaders/Loader";
import useStore from "../../store/store";

const DEFAULT_COUNTDOWN = null;
const DEFAULT_LEADERBOARD = null;
const DEFAULT_IS_AUTH_MODAL_OPEN = false;
const DEFAULT_IS_LOADING = false;

const calculateCountdown = () => {
    let year = new Date().getFullYear();
    const difference = +new Date(`${year}-05-20T00:00:00`) - +new Date();
    let countdown = {};
    if (difference > 0) {
        countdown = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)).toString().padStart(2, '0'),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24).toString().padStart(2, '0'),
            minutes: Math.floor((difference / 1000 / 60) % 60).toString().padStart(2, '0'),
            seconds: Math.floor((difference / 1000) % 60).toString().padStart(2, '0')
        };
    }
    return countdown;
};

const Leaderboard = () => {
    const [countdown, setCountdown] = useState(DEFAULT_COUNTDOWN);
    const [leaderboard, setLeaderboard] = useState(DEFAULT_LEADERBOARD);
    const [isAuthModalOpen, setIsAuthModalOpen] = useState(DEFAULT_IS_AUTH_MODAL_OPEN);
    const [isLoading, setIsLoading] = useState(DEFAULT_IS_LOADING);
    const { isUserLoading, user, getUser } = useStore((state) => ({
        isUserLoading: state.isUserLoading,
        user: state.user,
        getUser: state.getUser
    }));
    const navigate = useNavigate();

    useEffect(() => {
        getUser();
    }, []);

    useEffect(() => {
        getUsers();
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setCountdown(calculateCountdown());
        }, 1000);
        return () => clearTimeout(timer);
    });

    useEffect(() => {
        if (!isUserLoading && !user.username) {
            navigate("/");
        }
    }, [isUserLoading, user, navigate]);

    const getUsers = async () => {
        setIsLoading(true);
        try {
            const { data, status } = await axios.get("/api/e/v1/product/get_leaderboard");
            if (status === 200) {
                const leaderboard = data?.leaderboard;
                setLeaderboard(leaderboard);
            } else {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error getting users");
        } finally {
            setIsLoading(DEFAULT_IS_LOADING);
        }
    };

    const handleOpenAuthModal = () => {
        setIsAuthModalOpen(true);
    };

    return (
        <div className="page bg-authBackground min-h-screen flex flex-col justify-start full">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 full">
                <div className="flex flex-col items-center gap-4 py-5 text-center full">
                    <Header handleOpenModal={handleOpenAuthModal} />
                    {isLoading ?
                        <Loader /> :
                        <>
                            {leaderboard &&
                                <>
                                    <div className="text-center mb-4">
                                        <h1 className="text-lg sm:text-xl lg:text-2xl font-bold">Battle of the Buds:</h1>
                                        <h1 className="text-base sm:text-lg lg:text-xl font-bold">Round 1</h1>
                                        {countdown &&
                                            <p className="highlight text-base sm:text-lg lg:text-xl font-medium my-2">{countdown.days} : {countdown.hours} : {countdown.minutes} : {countdown.seconds}</p>
                                        }
                                        <p className="text-sm sm:text-base lg:text-lg">Review weed → Get weed.</p>
                                    </div>
                                    <div className="overflow-hidden rounded-lg">
                                        <table className="text-white w-full">
                                            <thead className="border-b border-green-600">
                                                <tr>
                                                    <th className="text-left py-2 px-4">USER</th>
                                                    <th className="text-right py-2 px-4">SCORE</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {leaderboard.map((user, index) => (
                                                    <tr key={user._id} className={`bg-green-${700 + (index % 2) * 100}`}>
                                                        <td className="py-2 px-4">{user.username}</td>
                                                        <td className="py-2 px-4 text-right">{user.count} pts</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="mt-4 text-center">
                                        <p className="text-xs sm:text-sm lg:text-base">
                                            The <span className="highlight">top 10 reviewers</span> will receive free prizes.
                                            Our past giveaways have included brands like <span className="highlight">Doja, STIIIZY, TBD, CBX, Sherbinskis, LOBO,</span> and more.
                                        </p>
                                    </div>
                                </>
                            }
                        </>
                    }
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default Leaderboard;