import React, { useEffect, useRef } from "react";

const DEFAULT_VERIFICATION_CODE = ["", "", "", "", "", ""];

const VerificationCode = ({ verificationCode, setVerificationCode, verificationCodeRestart, setVerificationCodeRestart }) => {
    const firstInputRef = useRef(null);

    useEffect(() => {
        if (verificationCodeRestart) {
            setVerificationCode(DEFAULT_VERIFICATION_CODE);
            setVerificationCodeRestart(false);
            if (firstInputRef.current) {
                firstInputRef.current.focus();
            }
        }
    }, [verificationCodeRestart, setVerificationCode, setVerificationCodeRestart]);

    const handleKeyDown = (e, i) => {
        if (e.key === "Backspace") {
            const newCode = [...verificationCode];
            if (e.target.value !== "") {
                newCode[i] = "";
            }
            setVerificationCode(newCode);
            if (e.target.value === "" && e.target.previousSibling) {
                e.target.previousSibling.focus();
            }
        }
    };

    const handleChange = (e, i) => {
        const newCode = [...verificationCode];
        const value = e.target.value;
        const regex = /^[0-9]*$/;
        if (regex.test(value)) {
            if (value.trim().length > 1) {
                e.clipboardData = {
                    getData: () => value.trim(),
                };
                handlePaste(e);
                return;
            }
            newCode[i] = value;
            setVerificationCode(newCode);
            if (e.target.nextSibling && value !== "") {
                e.target.nextSibling.focus();
            }
        }
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const paste = e.clipboardData.getData("text").split("");
        const regex = /^[0-9]*$/;
        if (paste.length === verificationCode.length && regex.test(paste.join(''))) {
            const newCode = Array(verificationCode.length).fill("");
            paste.forEach((char, index) => {
                newCode[index] = char;
            });
            setVerificationCode(newCode);
            e.target.blur();
        }
    };

    return (
        <div className="flex-col-left-center gap full">
            <label htmlFor="verification-code" className="text-white block mb-1 text-sm md:text-base">
                Verification code
            </label>
            <div className="flex justify-between mb-1">
                {verificationCode.map((n, i) => (
                    <input
                        key={i}
                        type="tel"
                        id={`verification-code-${i}`}
                        maxLength="1"
                        value={n}
                        onChange={(e) => handleChange(e, i)}
                        onKeyDown={(e) => handleKeyDown(e, i)}
                        onPaste={handlePaste}
                        className="w-full p-2 m-1 text-center rounded-md text-sm md:text-base"
                        style={{ fontSize: "16px" }}
                        ref={i === 0 ? firstInputRef : null}
                    />
                ))}
            </div>
        </div>
    );
};

export default VerificationCode;