import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { TextField, MenuItem, Rating, Select, TextareaAutosize } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { styled } from "@mui/system";
import Header from "../ui/layout/Header";
import Footer from "../ui/layout/Footer";
import AuthModal from "../ui/modals/AuthModal"
import useStore from "../../store/store";
import { LoadScriptNext, Autocomplete } from "@react-google-maps/api";

const REACT_APP_GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const DEFAULT_PRODUCT_RATING = 0;
const DEFAULT_PRODUCT_PRICE = "";
const DEFAULT_PRODUCT_NAME = "";
const DEFAULT_PRODUCT_TYPE = "";
const DEFAULT_PRODUCT_BRAND = "";
const DEFAULT_STORE_NAME_TEXT = "";
const DEFAULT_STORE_NAME = "";
const DEFAULT_STORE_LOCATION = "";
const DEFAULT_PRODUCT_COMMENT = "";
const DEFAULT_PRODUCT_IMAGE = null;
const DEFAULT_SHOULD_SUBMIT_REVIEW = false;
const DEFAULT_IS_AUTH_MODAL_OPEN = false;

const PRICE_OPTIONS = ["$1-$10", "$10-$20", "$20-$30", "$30-$50", "$50-$100", "$100+"];
const GOOGLE_MAPS_LIBRARIES = ["places"];

const StyledRating = styled(Rating)({
    "& .MuiRating-iconEmpty": {
        color: "#fff",
    }
});

const Review = () => {
    const [productRating, setProductRating] = useState(DEFAULT_PRODUCT_RATING);
    const [productPrice, setProductPrice] = useState(DEFAULT_PRODUCT_PRICE);
    const [productName, setProductName] = useState(DEFAULT_PRODUCT_NAME);
    const [productType, setProductType] = useState(DEFAULT_PRODUCT_TYPE);
    const [productBrand, setProductBrand] = useState(DEFAULT_PRODUCT_BRAND);
    const [storeNameText, setStoreNameText] = useState(DEFAULT_STORE_NAME_TEXT);
    const [storeName, setStoreName] = useState(DEFAULT_STORE_NAME);
    const [storeLocation, setStoreLocation] = useState(DEFAULT_STORE_LOCATION);
    const [productComment, setProductComment] = useState(DEFAULT_PRODUCT_COMMENT);
    const [productImage, setProductImage] = useState(DEFAULT_PRODUCT_IMAGE);
    const [shouldSubmitReview, setShouldSubmitReview] = useState(DEFAULT_SHOULD_SUBMIT_REVIEW);
    const [isAuthModalOpen, setIsAuthModalOpen] = useState(DEFAULT_IS_AUTH_MODAL_OPEN);
    const navigate = useNavigate();
    const { isUserLoading, user, getUser } = useStore((state) => ({
        isUserLoading: state.isUserLoading,
        user: state.user,
        getUser: state.getUser
    }));

    const productNameRef = useRef();
    const productTypeRef = useRef();
    const productBrandRef = useRef();
    const storeNameRef = useRef();
    const productCommentRef = useRef();
    const productImageRef = useRef();

    useEffect(() => {
        getUser();
    }, []);

    const handleImageUpload = async (e) => {
        let file = e.target.files[0];
        if (file && file.type === "image/heic") {
            const heic2any = require("heic2any");
            try {
                const convertedBlob = await heic2any({ blob: file, toType: "image/jpeg" });
                file = new File([convertedBlob], file.name.replace(/\.[^/.]+$/, ".jpg"), { type: "image/jpeg" });
            } catch (err) {
                console.error("Error converting HEIC to JPEG", err);
                toast.error("Error converting HEIC to JPEG");
                return;
            }
        }
        setProductImage(file);
    };

    const handleSubmitReview = async (override = false) => {
        try {
            if (!productRating) {
                toast.error("Please rate the product");
                return;
            }
            if (!productPrice) {
                toast.error("Please select a price range");
                return;
            }
            if (!productName) {
                toast.error("Please enter the product name");
                productNameRef.current.focus();
                return;
            }
            if (!productType) {
                toast.error("Please select a product type");
                productTypeRef.current.focus();
                return;
            }
            if (!productBrand) {
                toast.error("Please enter the product brand");
                productBrandRef.current.focus();
                return;
            }
            const validGoogleMapsLocation = await isValidGoogleMapsLocation(storeLocation);
            if (!storeName || !storeLocation || !validGoogleMapsLocation) {
                toast.error("Please enter a valid store name from Google Maps");
                storeNameRef.current.focus();
                return;
            }
            if (!productComment) {
                toast.error("Please write your review");
                productCommentRef.current.focus();
                return;
            }
            if (!productImage) {
                toast.error("Please upload an image");
                productImageRef.current.focus();
                return;
            }
            if (!isUserLoading && !user.username && !override) {
                toast.warning("Please sign up or log in first to submit your review");
                setShouldSubmitReview(true);
                handleOpenAuthModal();
                return;
            }
            const formData = new FormData();
            formData.append("store_name", storeName);
            formData.append("store_location", storeLocation);
            formData.append("product_name", productName);
            formData.append("product_type", productType);
            formData.append("product_brand", productBrand);
            formData.append("product_price", productPrice);
            formData.append("product_image", productImage);
            formData.append("rating", productRating);
            formData.append("comment", productComment);
            const { data, status } = await axios.post("/api/e/v1/product/create_review", formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });
            if (status === 200) {
                navigate(`/product/${data.review.product_id}?review=success`);
            } else {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error submitting review");
        }
    };

    async function isValidGoogleMapsLocation(location) {
        try {
            const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${location}&key=${REACT_APP_GOOGLE_MAPS_API_KEY}`);
            return response.data.status === "OK";
        } catch (error) {
            console.error(error);
            return false;
        }
    };

    const handleOpenAuthModal = () => {
        setIsAuthModalOpen(true);
    };

    const handleCloseAuthModal = () => {
        setIsAuthModalOpen(DEFAULT_IS_AUTH_MODAL_OPEN);
    };

    const handleLoad = (autocomplete) => {
        if (autocomplete) {
            autocomplete.addListener("place_changed", async () => {
                const place = autocomplete.getPlace();
                if (place) {
                    setStoreNameText(place.name);
                    setStoreName(place.name);
                    setStoreLocation(place.formatted_address);
                }
            });
        }
    };

    return (
        <div className="page bg-authBackground min-h-screen flex flex-col justify-start full overflow-x-hidden">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 full">
                <div className="flex flex-col items-center gap-4 py-5 text-center full">
                    <Header handleOpenModal={handleOpenAuthModal} />
                    <div className="flex-col-left-top gap full padding">
                        <div className="flex justify-start items-center text-white mt-2 cursor-pointer full" onClick={() => navigate("/")}>
                            <ArrowBackIosIcon />
                            <span className="ml-1">Back</span>
                        </div>
                        <h3 className="text-sm sm:text-lg lg:text-xl">Type of Product</h3>
                        <Select
                            fullWidth
                            value={productType}
                            onChange={(e) => setProductType(e.target.value)}
                            style={{ color: "white" }}
                            inputRef={productTypeRef}
                        >
                            <MenuItem value="flower" style={{ color: "black" }}>Flower</MenuItem>
                            <MenuItem value="cart" style={{ color: "black" }}>Cart</MenuItem>
                            <MenuItem value="edible" style={{ color: "black" }}>Edible</MenuItem>
                        </Select>
                        <h3 className="text-sm sm:text-lg lg:text-xl">Brand</h3>
                        <TextField
                            fullWidth
                            InputProps={{
                                style: {
                                    color: "white"
                                }
                            }}
                            placeholder="Cookies / STIIZY / Alien Labs / etc."
                            variant="outlined"
                            value={productBrand}
                            onChange={(e) => setProductBrand(e.target.value)}
                            inputRef={productBrandRef}
                            inputProps={{ maxLength: 50 }}
                        />
                        <small>{productBrand.length}/50</small>
                        <h3 className="text-sm sm:text-lg lg:text-xl">Name of Product</h3>
                        <TextField
                            fullWidth
                            InputProps={{
                                style: {
                                    color: "white"
                                }
                            }}
                            placeholder="Sour Diesel / Gelato / Zkittles"
                            variant="outlined"
                            value={productName}
                            onChange={(e) => setProductName(e.target.value)}
                            inputRef={productNameRef}
                            inputProps={{ maxLength: 50 }}
                        />
                        <small>{productName.length}/50</small>
                        <h3 className="text-sm sm:text-lg lg:text-xl">Name of Store</h3>
                        <div className="full">
                            <LoadScriptNext googleMapsApiKey={REACT_APP_GOOGLE_MAPS_API_KEY} libraries={GOOGLE_MAPS_LIBRARIES}>
                                <Autocomplete onLoad={handleLoad} onPlaceChanged={handleLoad}>
                                    <TextField
                                        fullWidth
                                        InputProps={{
                                            style: {
                                                color: "white"
                                            }
                                        }}
                                        placeholder="Enter store name"
                                        variant="outlined"
                                        value={storeNameText}
                                        onChange={(e) => setStoreNameText(e.target.value)}
                                        inputRef={storeNameRef}
                                    />
                                </Autocomplete>
                            </LoadScriptNext>
                        </div>
                        <h3 className="text-sm sm:text-lg lg:text-xl">Price</h3>
                        <div className="flex flex-row flex-wrap gap-2 sm:gap-4">
                            {PRICE_OPTIONS.map((option, i) => (
                                <button key={i} className={productPrice === option ? "button-primary" : "button-secondary"} onClick={() => setProductPrice(option)}>
                                    <p className="text-white">{option}</p>
                                </button>
                            ))}
                        </div>
                        <h3 className="text-sm sm:text-lg lg:text-xl">Was It Worth The Price?</h3>
                        <StyledRating
                            value={productRating}
                            onChange={(e, rating) => {
                                setProductRating(rating);
                            }}
                        />
                        <h3 className="text-sm sm:text-lg lg:text-xl">Write Your Review</h3>
                        <TextareaAutosize
                            fullWidth
                            className="text-white bg-[#3A5936] border-white border rounded-md p-2 outline-none"
                            value={productComment}
                            onChange={(e) => setProductComment(e.target.value)}
                            ref={productCommentRef}
                            maxLength={250}
                        />
                        <small>{productComment.length}/250</small>
                        <h3 className="text-sm sm:text-lg lg:text-xl">Upload Image</h3>
                        <input
                            type="file"
                            accept=".jpg,.jpeg,.png,.heic"
                            onChange={handleImageUpload}
                            className="text-white"
                            ref={productImageRef}
                        />
                        <div className="flex justify-center mt-1 sm:mt-2 full">
                            <button className="button-primary" onClick={() => handleSubmitReview()}>
                                <p className="text-black">Submit Review</p>
                            </button>
                        </div>
                    </div>
                    <Footer />
                </div>
                <AuthModal getUser={getUser} isModalOpen={isAuthModalOpen} handleCloseModal={handleCloseAuthModal} shouldSubmitReview={shouldSubmitReview} handleSubmitReview={handleSubmitReview} />
            </div>
        </div>
    );
};

export default Review;