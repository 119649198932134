import React from "react";
import Rating from "@mui/material/Rating";
import { formatRating, formatUTCDate } from "../../utils/formatters";

const ReviewCard = ({ product, review }) => {
    return (
        <div key={review.id} className="card flex-col-left-top full w-full md:w-1/2">
            {review?.product_image &&
                <div className="flex-col-left-top full">
                    <img src={review.product_image} alt={product.product_name} className="review-product-image" />
                </div>
            }
            <div className="flex-row-left-top full text-left">
                <div className="flex-col-left-top full">
                    <h2>{review.user_username}</h2>
                    <p className="text-xs md:text-base">Reviewed on {formatUTCDate(review.date)}</p>
                </div>
                <div className="flex-col-right-top full text-right">
                    <h2 className="truncate">{formatRating(review.rating)}</h2>
                    <Rating name="read-only" value={review.rating} readOnly style={{ margin: "0px", fontSize: "small" }} />
                    <p className="text-sm md:text-base text-[#9dff5b]">{review.product_price}</p>
                </div>
            </div>
            <div className="flex-col-left-top full">
                <p className="text-sm md:text-base">{review.comment}</p>
            </div>
        </div>
    );
};

export default ReviewCard;