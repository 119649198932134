import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useStore from "../../../store/store";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const DEFAULT_ANCHOR_EL = null;
const DEFAULT_IS_LOGGED_IN = false;

const Header = ({ handleOpenModal }) => {
    const [anchorEl, setAnchorEl] = useState(DEFAULT_ANCHOR_EL);
    const [isLoggedIn, setIsLoggedIn] = useState(DEFAULT_IS_LOGGED_IN);
    const { isUserLoading, user, getUser } = useStore((state) => ({
        isUserLoading: state.isUserLoading,
        user: state.user,
        getUser: state.getUser
    }));
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isUserLoading && user.username) {
            setIsLoggedIn(true);
        }
    }, [isUserLoading, user]);

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        getUser();
    }, []);

    const getPageName = () => {
        if (location.pathname.startsWith("/product/")) {
            return "Product";
        }
        switch (location.pathname) {
            case "/":
                return "Home";
            case "/review":
                return "Review";
            case "/leaderboard":
                return "Leaderboard";
            default:
                return "";
        }
    };

    return (
        <div className="flex flex-row justify-between items-center gap-4 md:gap-8 full">
            <img className="w-32 md:w-40" src="/images/WeedWise-Logo.png" alt="WeedWise Logo" onClick={() => navigate("/")} />
            {isLoggedIn ?
                <div className="flex-row-center-center gap relative">
                    <div className="button-ternary text-sm md:text-base">
                        {getPageName()}
                    </div>
                    <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={handleOpenMenu}
                        className="text-white"
                    >
                        <MoreHorizIcon style={{ color: "white" }} />
                    </IconButton>
                    <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                        className="text-sm md:text-base"
                    >
                        <MenuItem onClick={() => navigate("/")}>Home</MenuItem>
                        <MenuItem onClick={() => navigate("/leaderboard")}>Leaderboard</MenuItem>
                        <MenuItem><a href="/api/e/v1/auth/logout">Logout</a></MenuItem>
                    </Menu>
                </div> :
                <div className="flex-row-center-center gap relative">
                    <div className="button-ternary text-sm md:text-base">
                        {getPageName()}
                    </div>
                    <button className="button-secondary text-sm md:text-base" onClick={() => handleOpenModal()}>
                        <p>Log In</p>
                    </button>
                </div>
            }
        </div>
    );
};

export default Header;