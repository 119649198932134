import React, { useState } from "react";

const UsernameInput = ({ username, setUsername }) => {
    const [errorMessage, setErrorMessage] = useState("");

    const handleChange = (e) => {
        const value = e.target.value;
        const regex = /^[a-zA-Z0-9_]*$/;
        if (value.length > 15) {
            setErrorMessage("Username should not exceed 15 characters.");
        } else if (regex.test(value)) {
            setUsername(value);
            setErrorMessage("");
        } else {
            setErrorMessage("Username should not contain spaces or special characters.");
        }
    };

    return (
        <div className="flex-col-left-center gap full">
            <label htmlFor="username" className="text-white block mb-1 text-sm md:text-base">
                Username
            </label>
            <input
                id="username"
                type="text"
                value={username}
                onChange={handleChange}
                placeholder="john_doe123"
                className="w-full p-2 my-1 rounded-md text-sm md:text-base"
                style={{ fontSize: "16px" }}
            />
            {errorMessage && <p className="text-red-500 text-xs">{errorMessage}</p>}
        </div>
    );
};

export default UsernameInput;