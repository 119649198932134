import React from "react";
import { useNavigate } from "react-router-dom";
import Rating from "@mui/material/Rating";
import PlaceIcon from "@mui/icons-material/Place";
import { formatRating } from "../../utils/formatters";

const ProductCard = ({ product }) => {
    const navigate = useNavigate();

    return (
        <div key={product.id} className="card flex flex-col items-start" onClick={() => navigate(`/product/${product.id}`)}>
            <img src={product.product_image} alt={product.product_name} className="card-product-image" />
            <div className="flex flex-row justify-between w-full">
                <div className="flex flex-col items-start gap-2 w-3/5">
                    <h2 className="text-left break-words">{product.product_name}</h2>
                    <small className="truncate">by {product.product_brand}</small>
                    <a href={product?.store_url} target="_blank" rel="noopener noreferrer" className="flex items-center gap-1 p-0 cursor-pointer" onClick={(e) => e.stopPropagation()}>
                        <PlaceIcon style={{ margin: "0px", fontSize: "small" }} />
                        <small className="text-left">{product.store_name}</small>
                    </a>
                </div>
                <div className="flex flex-col items-end gap-2 w-2/5">
                    <h2 className="truncate">{formatRating(product.reviews_average)}</h2>
                    <Rating name="read-only" value={product.reviews_average} readOnly style={{ margin: "0px", fontSize: "small" }} />
                    <small className="truncate">({product.reviews_count} review{product.reviews_count === 1 ? "" : "s"})</small>
                    <p className="truncate text-[#9dff5b]">{product.product_price}</p>
                </div>
            </div>
        </div>
    );
};

export default ProductCard;