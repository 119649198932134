import React from "react";

const PhoneNumberInput = ({ phoneNumber, setPhoneNumber }) => {
    const handleChange = (e) => {
        const value = e.target.value;
        const regex = /^[0-9]*$/;
        if (regex.test(value)) {
            setPhoneNumber(value);
        }
    };

    return (
        <div className="flex-col-left-center gap full">
            <label htmlFor="phone-number" className="text-white block mb-1">
                Phone number
            </label>
            <input
                id="phone-number"
                type="tel"
                value={phoneNumber}
                onChange={handleChange}
                placeholder="1234567890"
                className="w-full p-2 my-1 rounded-md"
                style={{ fontSize: "16px" }}
            />
        </div>
    );
};

export default PhoneNumberInput;